.address {
    padding-bottom: 24rem;
    @media (max-width: $md) {
        padding-bottom: 154px;
    }
    .section__title {
        width: 75%;
        text-align: center;
        margin-bottom: 6.5rem;
        @media (max-width: $xl) {
            width: 100%;
        }
        @media (max-width: $md) {
                width: 100%;
                font-size: 64px;
        }

        span {
            display: block;
            &:nth-child(2) {
                padding-left: 36rem;
                margin-top: -2rem;
            }
            @media (max-width: $md) {
                &:nth-child(2) {
                    padding-left: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

.address__info {
    width: 59.2rem;
    margin-left: 25rem;
    margin-bottom: 8rem;

    font-size: 3.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    @media (max-width: $xl) {
        margin-left: 0;
    }
    @media (max-width: $md) {
        width: 100%;

        font-size: 18px;
    }
}

#address__map {
    position: relative;
    width: 100%;
    min-height: 80rem;

    @media (max-width: $md) {
        min-height: 450px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
}


