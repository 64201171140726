.footer {
    padding-bottom: 6rem;
    @media (max-width: $md) {
        padding: 60px 0;
    }
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md) {
        flex-direction: column;
    }
}

.footer__copyright, .footer__developer {
    font-size: 16px;
    opacity: 0.4;
    color: #101010;
    @media (max-width: $md) {
        font-size: 14px;
    }
}

.footer__copyright {
    @media (max-width: $md) {
        margin-bottom: 15px;
    }
}

.footer__navigation {
    display: none;
    padding: 0;
    @media (max-width: $md) {
        display: flex;
        margin-bottom: 78px;
        align-items: flex-start;
        .navigation__link {
            font-size: 24px;
        }
    }
}
