.desserts {
    position: relative;
    padding: 24rem 0;
    overflow: hidden;
    @media (max-width: $md) {
        padding: 34px 0 100px 0;
    }
}

.desserts__heading {
    width: 100%;
    max-width: 90.4rem;

    font-size: 3.2rem;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.5;
    @media (max-width: $xl) {
        max-width: 70.4rem;
    }
    @media (max-width: $lg) {
        max-width: 100%;
        width: 50%;
    }
    @media (max-width: $md) {
        width: 100%;
        margin-bottom: 39px;

        font-size: 18px;
    }
}

.desserts__header {
    display: flex;
    justify-content: space-between;
    @media (max-width: $lg) {
        align-items: center;
    }
    @media (max-width: $md) {
        flex-direction: column;
    }
}

.desserts-image {
    &_1 {
        margin-bottom: -3%;
        margin-left: 3%;
        @media (max-width: $xl) {
            margin-bottom: 5%;
        }
        @media (max-width: $lg) {
            width: 50%;
        }
        @media (max-width: $md) {
            width: 100%;
            order: 2;
            margin-bottom: 20%;
            margin-left: 0;
        }
    }
    &_2 {
        margin-left: 15%;
        margin-bottom: -15%;
        @media (max-width: $xl) {
           width: 50%;
           margin: 0 auto;
           margin-bottom: 10%;
        }
        @media (max-width: $lg) {
            margin: 0;
            margin-left: auto;
            margin-bottom: 10%;
        }
        @media (max-width: $md) {
            width: 100%;
            margin-left: 2%;
            margin-bottom: 20%;
        }

    }
    &_3 {
        margin-left: 60%;
        @media (max-width: $xl) {
            margin-left: 35%;
            margin-bottom: -39%;
         }
         @media (max-width: $lg) {
            margin: 0;
            width: 50%;
         }
         @media (max-width: $md) {
             width: 100%;
             margin-right: 2%;
         }
    }
    &_4 {
        margin-left: 8%;
        @media (max-width: $xl) {
            margin-left: 0%;
         }
         @media (max-width: $lg) {
            display: none;
         }
    }
}