.reserve {
    padding-top: 24rem;
    .section__title {
        width: 60%;
        margin-bottom: 6.5rem;

        text-align: center;
        @media (max-width: $xl) {
            width: 70%;
        }
        @media (max-width: $md) {
            width: 100%;
            font-size: 64px;
        }
        span {
            display: block;
            &:nth-child(2) {
                margin-top: -4rem;
                padding-left: 60rem;
                @media (max-width: $xl) {
                    padding-left: 36rem;
                }
                @media (max-width: $md) {
                    padding-left: 0;
                    margin-top: -2rem;
                }
            }
        }

    }
}

.reserve__content {
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: $md) {
        flex-direction: column;
        align-items: center;
    }
}

.reserve__form {
    width: 56rem;
    @media (max-width: $md) {
        width: 100%;
        margin-right: 0;
    }
}

.reserve__divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 9.6rem;
    @media (max-width: $md) {
        width: 100%;
        flex-direction: row;
        margin: 0;
    }
}

.reserve__line {
    width: 2px;
    height: 16.5rem;

    background: #707070;
    @media (max-width: $md) {
        width: 100%;
        height: 1px;
    }
}

.reserve__text {
    margin: 2.6rem 0;
    transform: rotate(-90deg);
    @media (max-width: $md) {
        font-size: 16px;
        transform: none;
        margin: 0 26px;
    }
}

.reserve__contacts {
    width: 59.2rem;
    @media (max-width: $md) {
        width: 100%;
    }
}

.reserve__phone {
    display: block;
    margin-bottom: 3.2rem;

    font-size: 6.4rem;
    font-family: "Hello Paris";
    span {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 28px;
        vertical-align: middle;
    }
    @media (max-width: $md) {
        margin-bottom: 24px;
        font-size: 48px;
    }
}

.reserve__address {
    font-size: 3.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    @media (max-width: $md) {
        font-size: 16px;
    }
}

.reserve__time {
    margin-bottom: 3.8rem;
    
    font-size: 2.4rem;
    color: #be8d57;
    @media (max-width: $md) {
        font-size: 14px;
    }
}