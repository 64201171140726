body {
    color: #101010;
    &.fixed {
        /* overflow: hidden; */
        @media (max-width: $md) {
            overflow: hidden;
        }
    }
}

.page {
    background: url("../img/design/bg.jpg");
    background-size: 100%;
}

.container {
    width: 100%;
    max-width: 192rem;
    margin: 0 auto;
    padding: 0 4rem;
    @media (max-width: $md) {
        max-width: 100%;
        padding: 0 16px;
    }
}

.container-lg {
    width: 100%;
    max-width: 124.6rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    @media (max-width: $md) {
        max-width: 100%;
    }
}

.lg {
    width: 100%;
    max-width: 124.6rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    @media (max-width: $md) {
        max-width: 100%;
    }
}
.section__title {
    font-size: 12rem;
    font-family: "Hello Paris";
    font-weight: normal;
    line-height: 1;
}

.title {
    margin: 0;
    margin-bottom: 8rem;

    font-size: 16rem;
    font-family: "Hello Paris";
    font-weight: 500;
    line-height: 0.75;
    color: #101010;
    text-align: center;

}

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 28rem;
    padding: 2.9rem 9.1rem 3rem;

    border-radius: 2px;
    border: solid 1px #101010;
    background: transparent;

    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    color: #101010;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-align: center;
    transition: 0.6s ease all;
    &:hover {
        transform: translateX(1rem);
        background: #be8d57;
        border-color: #be8d57;
        color: #fff;
    }
    @media (max-width: $md) {
        font-size: 12px;
    }
}

.offset {
    display: inline-block;
    padding-left: 20%;
}

.plus {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 26px;
    vertical-align: middle;
}

.menu {
    &.cocktails {
        padding-top: 20rem;
    }
}

.button__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
