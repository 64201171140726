@font-face {
    font-family: 'Cera Pro';
    font-display: swap;
    src: local('Cera Pro'), local('Cera Pro'),
        url('../fonts/CeraPro-Medium.woff2') format('woff2'),
        url('../fonts/CeraPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    font-display: swap;
    src: local('Cera Pro'), local('Cera Pro'),
        url('../fonts/CeraPro-Regular.woff2') format('woff2'),
        url('../fonts/CeraPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hello Paris';
    font-display: swap;
    src: local('Hello Paris'), local('Hello Paris'),
        url('../fonts/HelloParisSerif-Medium.woff2') format('woff2'),
        url('../fonts/HelloParisSerif-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hello Paris';
    font-display: swap;
    src: local('Hello Paris'), local('Hello Paris'),
        url('../fonts/HelloParisSerif-Regular.woff2') format('woff2'),
        url('../fonts/HelloParisSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

