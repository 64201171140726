.menu {
    padding: 12.3rem 0 8rem 0;
    background: #fff;
    position: relative;
    .section__title {
        margin-bottom: 1.6rem;
        text-align: center;
    }
    @media (max-width: $md) {
        padding: 34px 0 100px 0;
        .section__title {
            margin-bottom: 16px;
        }
    }
}

.menu__content {
    margin-bottom: 8rem;
    @media (max-width: $md) {
        margin-bottom: 40px;
    }
}

.menu-tabs__inner {
    position: sticky;
    top: 10%;
    z-index: 1;
    margin-bottom: 4rem;

    overflow: hidden;
    background: #fff;
    @media (min-width: 2560px)  {
        top: 8%;
    }
    @media (max-width: 2559px)  {
        top: 7%;
    }
    @media (max-width: 1980px)  {
        top: 7%;
    }
    @media (max-width: 1667px)  {
        top: 6%;
    }
    @media (max-width: 1450px) {
        top: 5%;
    }
    @media (max-width: $xl) {
        top: 7%;
    }
    @media (max-width: $lg) {
        top: 5%;
    }
    @media (max-width: $md) {
        top: 7%;
        margin-bottom: 30px;
    }
    @media (max-width: 650px) {
        top: 6%;
    }
    @media (max-width: 575px) {
        top: 4%;
    }
    @media (max-width: 430px) {
        top: 3%;
    }
    @media (max-width: 375px) {
        top: 2%;
    }
}

.menu-tabs__nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 9rem;
    padding: 3rem 1.5rem;
    background: #fff;
    overflow: hidden;
    @media (max-width: $md) {
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        height: 70px;
        padding: 40px 15px;
        &::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none;
            scrollbar-width: none
        }
    }
}

.menu-tabs__caption {
    @include reset-list;
    position: relative;
    margin-right: 4.1rem;
    padding-bottom: 16px;

    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #be8d57;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    &.active {
        color: #be8d57;
        border-bottom: 2px solid #be8d57;
        &:hover::after {
            content: none;
        }
    }

    @media (max-width: $xl) {
        padding-bottom: 17px;
    }
    @media (max-width: $lg) {
        padding-bottom: 14px;
    }
    
    @media (max-width: $md) {
        font-size: 16px;
        padding-bottom: 38px;
        height: 35px;
    }
    &:last-child {
        margin-right: 0;
    }
}

.menu-tabs__item {
    display: none;
    &.active {
        display: block;
    }
}

.menu__item {
    display: flex;
    padding: 4rem 0;

    border-top: 0.2rem solid #cccccc;
    &:last-child {
        border-bottom: 0.2rem solid #cccccc;
    }
    @media (max-width: $md) {
        flex-direction: column;
        padding: 24px 0;
    }
}

.menu__block {
    margin-right: 3.2rem;
    width: calc(50% - 3.2rem);
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: $md) {
        width: 100%;
        margin-right: 0;
    }
}

.menu__caption {
    font-size: 2.4rem;
    font-weight: normal;

    color: #101010;
    @media (max-width: $md) {
        margin-bottom: 23px;
        font-size: 18px;
    }
}

.menu__descr {
    margin-bottom: 3.6rem;
    font-size: 16px;
    @media (max-width: $md) {
        margin-bottom: 14px;
        font-size: 14px;
    }
}

.menu__price {
    font-size: 3.2rem;
    span {
        color: #cccccc;
    }
    @media (max-width: $md) {
        font-size: 24px;
    }
}

.products__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-auto-rows: 1fr;
    gap: 3.2rem;
    margin-bottom: 4rem;
    @media (max-width: $xl) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 1650px) {
        grid-template-rows: minmax(500px, auto);
        grid-auto-rows: minmax(500px, auto);
    }
    @media(max-width:$lg) {
        grid-template-rows: minmax(500px, auto);
        grid-auto-rows: minmax(500px, auto);
    }
    @media(max-width:$md) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: minmax(400px, auto);
        grid-auto-rows: minmax(400px, auto);
        gap: 32px;
    }

}

.products__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.4rem 2.4rem 4rem 2.4rem;
    border: solid 1px #101010;
    transition: 0.3s all;
    color: #101010;
    &.large {
        grid-column: 3/5;
        @media (max-width: $xl) {
            grid-column: initial;
        }
    }
    &.large-2 {
        grid-column: 1/3;
        @media (max-width: $xl) {
            grid-column: initial;
        }
    }
    &:hover {
        border-color: #be8d57;
    }
    
}

.products__img {
    margin-bottom: 6.6rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.products__caption {
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 1.33;
    @media(max-width:$md) {
        font-size: 22px;
    }
}

.products__compound {
    text-align: center;
    font-size: 16px;
    @media(max-width:$md) {
        font-size: 14px;
    }
}

.menu__footer {
    display: flex;
    justify-content: center;
}

.menu__btn {
    margin: 0 auto;
    @media (max-width: $md) {
        max-width: 100%;
    }
}

.menu-filter {
    overflow: hidden;
    margin-bottom: 4rem;
}

.filter__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2.4rem;
    @media(max-width:$md) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.filter__caption {
    display: inline-block;
    width: 28rem;
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: 1.2px;
    @media(max-width:$md) {
        width: 100%;
        margin-bottom: 16px;

        font-size: 16px;
    }
}

.filter__radios {
    width: calc(100% - 28rem);
    @media(max-width:$md) {
        width: 100%;
        display: flex;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}