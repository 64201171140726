// .form {
//     margin: -15px -30px;
// }


// .form__section {
//     margin: -15px -30px;
//     padding: 0 15px;
// }

// .form__content {
//     margin: -15px -30px;
// }

// .form__caption {
//     padding: 0 15px;
// }

// .field {
//     width: 100%;
//     margin-bottom: 30px;
//     padding: 0 15px;
// }

// .field_6 {
//     width: 50%;
// }

// .field_8 {
//     width: 66.66%;
// }

// .field_4 {
//     width: 33.33%;
// }

// .field_3 {
//     width: 25%;
// }


// .control {
    
// }


textarea, input:matches(
  [type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]
) 
{
  -webkit-appearance: none;
  -webkit-border-radius:0;
};

.form {

}

.form__section {
    margin-bottom: 3.9rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.form__content {
    display: flex;
}

.form__caption {
    margin-bottom: 2.4rem;
    padding: 0 1.5rem;

    font-size: 2.4rem;
    font-weight: normal;
}

.field {
    position: relative;
    width: 100%;
    margin-bottom: 1.6em;
    padding: 0 1.5rem;
    @media (max-width: $md) {
        margin-bottom: 24px;
    }
}

.field_6 {
    width: 50%;
}

.field_4 {
    width: 33.3%;
}

.label {
    position: absolute;
    top: 3.5rem;
    left: 1.5rem;

    font-size: 24px;
    transition: 0.3s all;
    color: #a29789;
    &.active {
        top: 2.6rem;
        font-size: 16px;
        color: #be8d57;
    }
    &.label__select {
        top: 2rem;
        &.active {
            top: 0.8rem;
        }
    }
    @media (max-width: $lg) {
        top: 1.5rem;
        font-size: 18px;
        &.active {
            top: 0;
            font-size: 12px;
        }
        &.label__select {
            top: 1.5rem;
            font-size: 18px;
            &.active {
                top: 0;
                font-size: 12px;
            }
        }
    }
}

#input__time::placeholder {
    opacity: 0;
}

#input__date::placeholder {
    opacity: 0;
}

#input__time:focus::placeholder {
   opacity: 1;
}
#input__date:focus::placeholder {
    opacity: 1;
 }
    

.input {
    display: block;
    width: 100%;
    padding: 4.9rem 0 2.2rem 0;
    position: relative;

    font-size: 2.4rem;
    color: #101010;
    border-color: #707070;
    border-width: 0 0 2px;
    background-color: transparent;
    &:focus {
        outline: none;
    }
    @media (max-width: $xl) {
        border-width: 0 0 1px;
    }
}

.select {
    position: relative;
    width: 100%;
    padding: 1.8rem 0 2.2rem 0;

    background-color: transparent;
}


textarea {
    width: 100%;
    padding: 3.2rem 3.2rem 12.1rem 3.2rem;

    font-size: 1.8rem;
    background-color: transparent;
    resize: none;
    &:focus {
        outline: none;
    }
}

.form__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 3.2rem 4rem;
    width: 100%;

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #fff;
    background: #be8d57;
    border: none;
    transition: 0.6s all;
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
    }
}

.select2-container--default .select2-selection--single {
    background: transparent;
    border-color: #707070;
    border-width: 0 0 2px;
    outline: none;
    @media (max-width: $xl) {
        border-width: 0 0 1px;
    }
}

.select2-container .select2-selection--single {
    padding: 4.2rem 0 4.2rem;
    font-size: 2.4rem;
    color: #a29789;
    outline: none;
    @media (max-width: $md) {
        font-size: 18px;
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
    margin-top: -1.4rem;

    color: #a29789;
    outline: none;
    @media (max-width: $md) {
        margin-top: -14px;
    }
}


.select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 35%;
    right: 0;
    @media (max-width: $md) {
        top: 30%;
    }
}

.select2-results__option {
    padding: 2rem;

    font-size: 18px;
    text-align: center;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background: #be8d57;
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 25rem;
    background: url("../img/design/bg.jpg");
    background-size: 100%;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
        background: #a29789;
        border-radius: 10px;
    }
    @media (max-width: $md) {
        max-height: 400px;
    }
}

.radio__btn {
    display: inline-block;
    margin: 0 0.8rem 1.4rem 0;

    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-weight: 500;
    transition: 0.3s all linear;
    color: #be8d57;
    input[type="checkbox"] {
        display: none;
    }
    label {
        display: inline-block;
        padding: 2rem 2.4rem;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 2px;
        background-color: #fff8f2;
        user-select: none;
        transition: 0.3s all linear;
        &:hover {
            border-color: #be8d57;
        }
        @media(max-width:$md) {
            padding: 16px 20px;
        }
    }
    @media(max-width:$md) {
        font-size: 12px;
    }
}

.radio__btn input[type="checkbox"]:checked + label {
    transition: 0.3s all linear;
    background: #be8d57;
    color: #fff;
}

