.description {
    padding-top: 24rem;
    padding-bottom: 24rem;
    @media (max-width: $md) {
        padding-bottom: 100px;
    }
}

.description__content {
    display: flex;
    flex-direction: column;
}

.description__item {
    &:nth-child(1) {
        padding-bottom: 11.7rem;
        @media (max-width: $md) {
            padding-top: 8px;
        }
    }
    &:nth-child(2) {
        display: flex;
        margin-bottom: 3.2rem;
        @media (max-width: $md) {
            margin-right: 32px;
            margin-bottom: 40px;
        }
    }
    &:nth-child(3) {
        display: flex;
        justify-content: flex-end;
        @media (max-width: $lg) {
            .description__img {
                margin-right: 3.2rem;
            }
            .description__text {
                margin-right: 0;
            }
        }
        @media (max-width: $md) {
            .description__img {
                margin-right: 0;
                margin-left: 32px;
            }
        }
    }
}

.description__caption {
    width: 90.4rem;
    font-size: 3.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    @media (max-width: $lg) {
        width: 100%;
    }
    @media (max-width: $md) {
        font-size: 18px;
    }
}

.description__btn {
    align-self: flex-start;
    margin-right: 3.2rem;
    &.hidden {
        display: none;
    }
    @media (max-width: $lg) {
        display: none;
        &.hidden {
            margin-top: 10rem;
            display: inline-flex;
            align-self: center;
            margin-right: 0;
        }
    }
    @media (max-width: $md) {
        &.hidden {
            max-width: 100%;
        }
    }
}

.description__text {
    width: 43.6rem;
    margin-right: 3.2rem;
    align-self: center;

    font-size: 2.4rem;
    line-height: 1.5;
    @media (max-width: $lg) {
        width: 100%;

        text-align: left;
        font-size: 16px;
    }
    @media (max-width: $md) {
        display: none;
    }
}

.description__img {
    width: 60%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-another {
        margin-right: 18rem;
        width: 50%
    }
    @media (max-width: $lg) {
        width: 100%;
        &-another {
            margin-right: 0;
            width: 100%
        }
    }
}