.about {
    padding: 25rem 0 8rem 0;
    position: relative;
    .title {
        padding: 0 12rem;
        @media(max-width:$md) {
            font-size: 46px;
            line-height: 1;
            padding: 0;
            margin-bottom: 30px;
        }
    }
}

.about__subtitle {
    font-size: 3.2rem;
    max-width: 90.4rem;
    line-height: 1.5;
    text-transform: uppercase;
    span {
        display: inline-block;
        text-indent: 30rem;
    }
    @media(max-width:$xl) {
        max-width: 100%;
    }
    @media(max-width:$md) {
        font-size: 18px;
        span {
            text-indent: 50px;
        }
    }
}

.about__top {
    position: relative;
    padding: 24rem 0;
    display: flex;
    justify-content: flex-end;
    @media(max-width:$xl) {
        justify-content: flex-start;
    }
    @media(max-width:$lg) {
        justify-content: center;
    }
    @media(max-width:$md) {
        display: block;
        padding: 80px 0;
    }
}

.about__image {
    @media(max-width:$lg) {
        width: 80%;
    }
    @media(max-width:$md) {
        width: 100%;
        padding: 20px 0;
    }
}

.about__video {
    position: absolute;
    left: 40%;
    top: 50%;
    transform: translate(-40%, -50%);
    @media(max-width:$xl) {
        width: 70%;
        video {
            width: 100%;
        }
        top: 80%;
        left: 50%;
        transform: translate(-50%, -80%);
    }
    @media(max-width:$lg) {
        width: 60%;
    }
    @media(max-width:$md) {
        width: 100%;
        position: static;
        transform: none;
    }
}

.about__badge {
    position: absolute;
    top: 10%;
    left: 50%;
    max-width: 20rem;
    animation: rot infinite linear 60s;
    will-change: transform;
    @media(max-width:$xl) {
        top: 0;
    }
}

.about-text {
    display: flex;
    justify-content: center;
    padding-bottom: 24rem;
    @media(max-width:$lg) {
        padding-bottom: 14rem;
    }
    @media(max-width:$md) {
        padding-bottom: 80px;
    }
}

.about-text__center {
    max-width: 59.2rem;
    font-size: 2.4rem;
    line-height: 1.5;
    @media(max-width:$md) {
        width: 100%;
        max-width: 100%;
        padding: 0 32px;
        text-align: center;
        font-size: 16px;
    }
}

.about__banner {
    width: 100%;
    overflow-y: hidden;
    margin-bottom: 24rem;
    video {
        width: 100%;
    }
    @media(max-width:$lg) {
        padding-bottom: 0;
        margin-bottom: 8rem;
        height: auto;
    }
}

.about__info {
    @media(max-width:$xl) {
        overflow: hidden;
    }
}


.about-info__row {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 8rem;
    @media(max-width:$lg) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 10%;
    }
    &.row_2 {
        justify-content: flex-start;
        align-items: flex-end;
        padding-bottom: 32rem;
        margin-bottom: 0;
        .about__badge {
            top: 20%;
            left: 60%;
            @media(max-width:$xl) {
                display: none;
            }
        }
        @media(max-width:$lg) {
            align-items: center;
            flex-direction: row;
        }
        @media(max-width:$md) {
            flex-direction: column;
            padding-bottom: 80px;
        }
    }
}

.about-info__descr {
    width: 100%;
    max-width: 59.2rem;
    @media(max-width:$lg) {
        width: 100%;
        margin-bottom: 20rem;
    }
    @media(max-width:$md) {
        max-width: 100%;
        margin-bottom: 60px;
    }
}

.about-info__img {
    @media(max-width:$lg) {
        width: 100%;
        margin-bottom: -25%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


.about-info__caption {
    margin-bottom: 8rem;
    line-height: 1.5;
    font-weight: normal;
    font-size: 3.2rem;
    text-transform: uppercase;
    @media(max-width:$md) {
        text-align: center;
        font-size: 18px;
    }
}

.about-info__text {
    font-size: 2.4rem;
    @media(max-width:$md) {
        padding: 0 32px;
        font-size: 16px;
        text-align: center;
    }
}

.about-info__banner {
    @media(max-width:$xl) {
        max-width: 50%;
    }
    @media(max-width:$lg) {
        margin-right: 4rem;
    }
    @media(max-width:$md) {
        margin-right: 0;
        margin-bottom: 120px;
        max-width: 100%;
    }
}

.about-info__video {
    position: absolute;
    right: 4%;
    top: calc(60% + 8rem);
    z-index: 1;
    @media(max-width:$xl) {
        right: 0;
        top: 100%;
        video {
            width: 70%;
        }
    }
    @media(max-width:$lg) {
        position: static;
        width: 100%;
        display: flex;
        justify-content: center;
        video {
            width: 60%;
        }
    }
    @media(max-width:$md) {
        video {
            width: 80%;
        }
    }
}

.about-info__sign {
    max-width: 43.6rem;
    margin-left: auto;
    font-size: 2.4rem;
    @media(max-width:1720px) {
        margin-left: 2rem;
    }
    @media(max-width:$lg) {
        max-width: 100%;
        margin: 0;
    }
    @media(max-width:$md) {
        padding: 0 32px;
        font-size: 16px;
        text-align: center;
    }
}

/* .about__marquee {
    overflow: hidden;
    width: 100%;
    height: 100%;
} */

.about__marquee {
    position: absolute;
    top: 45%;
    width: 100%;
    height: 120px;
    background: url("../img/design/central_move.svg") repeat-x;
    animation: marquee 100s linear infinite;
    @media(max-width:1921px) {
        top: 48%;
    }

    @media(max-width:1821px) {
        top: 49%;
    }
    @media(max-width:1721px) {
        top: 50%;
    }
    @media(max-width:1621px) {
        top: 50%;
    }
    @media(max-width:1421px) {
        top: 49%;
    }
    @media(max-width:1321px) {
        top: 50%;
    }
    @media(max-width:$xl) {
        background-size: 70%;
        top: auto;
        bottom: -100px;
    }
    @media(max-width:$md) {
        background-size: 60%;
        top: auto;
        bottom: -100px;
    }
}

.about__title {
    width: 100%;

    font-size: 8rem;
    line-height: 1.1;
    text-transform: uppercase;
    padding: 0 0rem 24rem 0rem;
    span {
        display: inline-block;
        text-indent: 60rem;
    }
    @media(max-width:$xl) {
        font-size: 6rem;
    }
    @media(max-width:$md) {
        padding-bottom: 14rem;
        font-size: 22px;
        line-height: 1.5;
        span {
            text-indent: 60px;
        }
    }
}

.about-features {
    width: 100%;
    max-width: 152.8rem;
    margin: 0 auto;
    @media(max-width:$md) {
        max-width: 100%;
    }
}

.about-features__content {
    display: flex;
    width: 100%;
    height: 100%;
    @media(max-width:$md) {
        flex-direction: column;
        align-items: center;
    }
}

.about-features__column {
    width: 50%;
    margin-right: 3.2rem;
    &.column_2 {
        margin-right: 0;
        padding-top: 36.8rem;
        @media(max-width:$xl) {
            padding-top: 29.8rem;
        }
        @media(max-width:$md) {
            padding-top: 0;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media(max-width:$md) {
        width: 100%;
        margin-right: 0;
    }
}

.about-features__video {
    video {
        width: 100%;
    }
}

.about-features__text {
    margin: 18rem 0;
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
}

.about-features__img {
    margin-bottom: 12rem;
}

.about-description {
    padding: 24rem 0;
    @media(max-width:$lg) {
        padding: 12rem 0;
    }
    @media(max-width:$md) {
        padding: 40px 0;
    }
}

.about-description__row {
    display: flex;
    margin-bottom: 8rem;
    &.row_2 {
        justify-content: flex-end;
        margin-right: 4rem;
        @media(max-width:$md) {
            margin-right: 0;
            justify-content: flex-start;
        }
    }
    @media(max-width:$md) {
        margin-bottom: 40px;
    }
}

.about-description__title {
    width: 100%;
    max-width: 90.4rem;
    font-size: 3.2rem;
    text-transform: uppercase;
    span {
        display: inline-block;
        text-indent: 16rem;
    }
    @media(max-width:$md) {
        font-size: 18px;
        max-width: 100%;
    }
}

.about-description__text {
    width: 100%;
    max-width: 43.6rem;
    font-size: 2.4rem;
    @media(max-width:$md) {
        max-width: 100%;
        padding: 0 32px;
        text-align: center;
        font-size: 16px;
    }
}

.about-footer {
    width: 100%;
    background: url("../img/uploads/about_footer.png") no-repeat center center/cover;
    background-attachment: fixed;
    padding-bottom: 70%;
    position: relative;
    @media(max-width:$md) {
        background-attachment: scroll;
        padding-bottom: 100%;
    }
}

.about-footer__text {
    width: 100%;
    max-width: 98.4rem;
    position: absolute;
    top: 30%;
    right: 0;
    font-size: 3.2rem;
    text-transform: uppercase;
    color: #fff;
    @media(max-width:$md) {
        font-size: 18px;
        text-align: center;
        max-width: 100%;
        top: 25px;
    }
}

@keyframes marquee {
    0% { background-position: 0;}
    100% { background-position: -1200px; }
}
