.decoration {
    padding: 12rem 0;
}

.decoration__wrapper {
    padding-right: 10%;
    @media (max-width: $lg) {
        padding-right: 0;
    }
}

.decoration__image {
    position: relative;
    z-index: 2;
    margin-left: 80%;
    margin-bottom: -20%;
    max-width: 43.6rem;
    img {
        display: block;
    }
    @media (max-width: $xl) {
        max-width: 100%;
    }
    @media (max-width: $md) {
        margin-left: 5%;
        margin-bottom: 25%;
        max-width: 70%;
    }
}

.decoration__image-2 {
    margin-left: 10%;
    max-width: 28rem;
    position: relative;
    z-index: 2;
    img {
        display: block;
    }
    @media (max-width: $xl) {
        max-width: 100%;
        margin-left: 0;
    }
    @media (max-width: $md) {
        display: none;
    }
}

.decoration__image-3 {
    position: relative;
    z-index: 2;
    margin-left: 65%;
    margin-top: -8%;
    max-width: 74.8rem;
    img {
        display: block;
    }
    @media (max-width: $xl) {
        max-width: 100%;
        margin-left: 35%;
    }
    @media (max-width: $md) {
        margin-left: 18%;
        max-width: 80%;
    }

}


.decoration__video {
    max-width: 74.8rem;
    margin: 0 auto;
    margin-bottom: -7%;
    position: relative;
    z-index: 1;
    video {
        width: 100%;
    }
    @media (max-width: $xl) {
        margin-bottom: 5%;
    }
    @media (max-width: $lg) {
        max-width: 100%;
    }
    @media (max-width: $md) {
        margin-bottom: 25%;
    }
}