.events {
    padding: 25rem 0 8rem 0;
    background: #fff;
}

.events__descr {
    width: 100%;
    max-width: 90.4rem;
    margin-bottom: 4rem;

    font-size: 3.2rem;
    text-transform: uppercase;
    span {
        display: inline-block;
        text-indent: 33rem;
    }
    @media (max-width: $md) {
        font-size: 18px;
        max-width: 100%;
        span {
            text-indent: 37px;
        }
    }
    @media (max-width: 374px) {
        font-size: 16px;
        span {
            text-indent: 30px;
        }
    }
}

.events__list {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3.2rem 0 0;
    @media (max-width: $md) {
        margin: 0;
    }
}

.events__item {
    margin: 0 3.2rem 3.2rem 0;
    width: calc(33.3% - 3.2rem);
    padding: 3.2rem 3.2rem 5.6rem;

    border: solid 1px #101010;
    background-color: rgba(255, 255, 255, 0);
    transition: 0.3s all;
    @media (max-width: $xl) {
        width: calc(50% - 3.2rem);
    }
    @media (max-width: $md) {
        width: 100%;
        margin-right: 0;
    }
    &:hover {
        border-color: #be8d57;
    }
}

.events__img {
    position: relative;
    padding-bottom: calc(528/528 * 100%);
    margin-bottom: 4rem;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.events__caption {
    margin-bottom: 1.6rem;

    font-size: 2.4rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.33;
    font-weight: normal;
    @media (max-width: $md) {
        font-size: 18px;
    }
}

.events__date {
    display: block;
    font-size: 1.6rem;
    text-align: center;
    @media (max-width: $md) {
        font-size: 12px;
    }
}