.social__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 16px;

    border-radius: 25px;
    border: solid 1px #be8d57;
    background-color: rgba(255, 255, 255, 0);
    transition: 0.6s all;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: #be8d57;
        path {
            fill: #fff;
        }
    }
}