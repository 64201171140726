*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
    font-size: 0.5208333333333333vw;
    @media (max-width: $xl) {
        font-size: 0.7818608287724785vw;
    }
    @media (max-width: $lg) {
        font-size: 0.7818608287724785vw;
    }
    @media (max-width: $md) {
        font-size: 1.302083333333333vw;
    }
}

body {
    height: 100%;
    font-family: 'Cera Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem;
    line-height: 1.5;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

.page {
    background-color: #fdfbf9;
}

