.header {
    position: fixed;
    width: 100%;
    min-height: 13rem;
    display: flex;
    align-items: center;
    background: url(../img/design/bg.jpg);
    background-size: 100%;
    

    z-index: 9999;
    transition: 0.4s linear all;
}

.header__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.header__menu {
    display: flex;
    width: 50%;
    padding: 1rem 1rem 0 1rem;
    align-items: center;
    cursor: pointer;
    &:hover {
        .header__hamburger span {
            transition: 0.6s all;
            &:nth-child(1) {
                transform: translateY(-2px);
            }
            &:nth-child(2) {
                transform: translateY(2px);
            }
        }
    }
}

.header__caption {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #101010;
    @media (max-width: $md) {
        display: none;
    }
}

.header__hamburger {
    display: flex;
    flex-direction: column;
    margin-right: 1.8rem;
    margin-top: 5px;
    cursor: pointer;
    span {
        width: 24px;
        height: 2px;
        margin-bottom: 6px;
        display: block;
        background: #000;
        transition: 0.3s all;
    }
    @media (max-width: $md) {
        margin-right: 0;
    }
}

.header__logo {
    justify-self: center;
    @media (max-width: $md) {
        max-width: 176px;
    }
}

.header__contacts {
    justify-self: flex-end;
    display: flex;
    align-items: center;
}

.header__socials {
    display: flex;
    margin-right: 4rem;
    @media (max-width: $xl) {
        display: none;
    }
}

.header__phone {
    font-family: "Hello Paris";
    font-size: 4rem;
    font-weight: 500;
    line-height: 1;
    color: #101010;
    @media (max-width: $lg) {
        span {
            display: none;
        }
    }
}

.phone__icon {
    display: none;
    @media (max-width: $lg) {
        display: block;
    }
}

.drawer{
    position: fixed;
    visibility: hidden;
    width: 100%;
    opacity: 0;

    height: 100vh;
    z-index: 10000;
    background-color: #fdfbf9;
    transition: 0.5s opacity linear, 0.5s visibility linear;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}

.drawer__top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    min-height: 13rem;

    cursor: pointer;
}

.drawer__close {
    display: flex;
    align-items: center;
    padding-top: 0.8rem;
    padding-left: 0.9rem;

    cursor: pointer;
    @media (max-width: $md) {
        /* width: 38px;
        height: 32px; */
    }
}

.drawer__icon {
    margin: 0.2rem 0 0 0.2rem;
}

.drawer__caption {
    margin-left: 1.4rem;

    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #101010;
    @media (max-width: $lg) {
        display: none;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem 0 0 0;
    @media (max-width: $md) {
        padding-top: 8rem;
    }

}

.navigation__link {
    width: auto;
    margin-bottom: 2.9rem;

    font-size: 5.6rem;
    font-weight: 500;
    color: #101010;
    line-height: 1;
    text-transform: uppercase;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: $md) {
        font-size: 24px;
        margin-bottom: 18px;
    }
}