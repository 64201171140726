.goods {
    padding: 25rem 0 8rem 0;
    color: #101010;
    @media (max-width: $md) {
        padding: 20rem 0 8rem 0;
    }
}

.goods__wrapper {
    display: flex;
    @media (max-width: $md) {
        flex-direction: column;
    }
}

.goods__img {
    width: calc(60% - 13.2rem);
    margin-right: 13.2rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: $xl) {
        width: calc(50% - 13.2rem);
    }
    @media (max-width: $lg) {
        width: calc(50% - 9rem);
    }
    @media (max-width: $md) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
    }
}

.goods__descr {
    width: 40%;
    @media (max-width: $xl) {
        width: 50%;
    }
    @media (max-width: $md) {
        width: 100%;
    }
}

.goods__title {
    margin: 0;
    margin-bottom: 4rem;

    font-size: 12rem;
    line-height: 1;
    font-family: "Hello Paris";
    font-weight: 500;
    @media (max-width: $md) {
        font-size: 10rem;
        text-align: center;
    }
}

.goods__compound {
    margin-bottom: 4rem;
    text-transform: uppercase;
    font-size: 3.2rem;
    @media (max-width: $md) {
        font-size: 18px;
        text-align: center;
    }
}

.goods__text {
    margin-bottom: 8rem;
    font-size: 2.4rem;
    @media (max-width: $md) {
        font-size: 14px;
        text-align: center;
    }
}

.goods__badge {
    display: flex;
    max-width: 20rem;
    animation: rot 40s linear 0s infinite;
    -webkit-animation: rot 40s linear 0s infinite;
    will-change: transform;
    @media (max-width: $md) {
        justify-content: center;
        margin: 0 auto;
        max-width: 150px;
    }
}

.goods__products {
    padding-top: 12rem;
    background: #fff;
}

@keyframes rot {
    from {
      -webkit-transform:rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform:rotate(360deg);
      transform: rotate(360deg);
    }
  }