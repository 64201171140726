.intro {
    position: relative;
    padding-top: 20rem;
}

.intro__banner {
    background: url("../img/uploads/banner.png") no-repeat center center/cover;
    min-height: 100vh;
}

.intro__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    margin-bottom: 8rem;
    @media (max-width: $md) {
        position: relative;
        margin-bottom: 0;
        height: 100vh;
        justify-content: flex-start;
        padding-top: 30px;
    }
}

.intro__phrase {
    width: 59.2rem;
    margin-bottom: 2.4rem;

    font-size: 3.2rem;
    text-align: center;
    line-height: 1.25;
    @media (max-width: $md) {
        margin-bottom: 20px;
        width: 80%;
        font-size: 24px;
        line-height: 1.5;
    }
}

.unique {
    color: #be8d57;
}

.intro__move {
    position: relative;
    width: 10000vw;
    min-height: 26rem;
    overflow: hidden;
    @media (max-width: $md) {
        margin-bottom: 50px;
    }
    @media (max-width: 575px) {
        margin-bottom: -30px;
    }
}

.intro__title {
    margin: 0;

    white-space: nowrap;
    font-size: 20rem;
    font-family: "Hello Paris";
    line-height: 0.6;
    text-align: center;
    font-weight: normal;

    animation: move__title 85s linear infinite;
    will-change: transform;
    transition: 0.6s all;
    @media (max-width: $md) {
        font-size: 64px;
        line-height: 1.25;
        animation: move__mob 40s linear infinite;
    }

}

.intro__arrow {
    animation: up-down 3s linear 0s infinite;
    -webkit-animation: up-down 3s linear 0s infinite;
    will-change: transform;
    min-height: 80px;
        @media (max-width: $md) {
            
            svg {
                height: 41px;
            }
        }
}

.intro__badge {
    position: absolute;
    top: 33.5%;
    right: 25%;

    animation: rot 40s linear 0s infinite;
    -webkit-animation: rot 40s linear 0s infinite;
    will-change: transform;
    img {
        width: 20rem;
    }
    @media (max-width: $lg) {
        top: 30%;
        right: 8%;
    }
    @media (max-width: $md) {
        position: static;
        margin-bottom: 20px;
        img {
            width: 140px;
        }
    }
}

@keyframes move__title {
    from {
        -webkit-transform:translateX(-5vw);
        transform: translateX(-5vw);
    }
    to {
        -webkit-transform:translateX(-150vw);
        transform: translateX(-150vw);
    }
}


@keyframes move__mob {
    from {
        -webkit-transform:translateX(25vw);
        transform: translateX(25vw);
    }
    to {
        -webkit-transform:translateX(-150vw);
        transform: translateX(-150vw);
    }
}




@keyframes  up-down {
    0% {
        -webkit-transform:translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform:translateY(15%);
        transform: translateY(15%);
    }
    100% {
        -webkit-transform:translateY(0);
        transform: translateY(0);
    }
}

@keyframes rot {
    from {
      -webkit-transform:rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform:rotate(360deg);
      transform: rotate(360deg);
    }
  }